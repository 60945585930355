import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';

const STATE_STORAGE_KEY = 'state-navigation';

@Injectable({
  providedIn: 'root'
})
export class StateNavigationService {

  constructor(
    private $storage: StorageService) { }

  saveNavigationState(state: any) {
    this.$storage.set(STATE_STORAGE_KEY, state, false);
  }

  getNavigationState() {
    return this.$storage.get(STATE_STORAGE_KEY, false);
  }
}
