import {
  EventEmitter,
  Injectable
} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventsService {
  private _emitter = new EventEmitter<any>();
  private _resolvedEvents = {};

  subscribe (_type, subscriber) {
    // En caso de que el valor ya se haya emitido despachamos el subscriber inmediatamente,
    // aún así, dejamos que se vuelva a subscribir si se vuelve a emitir un nuevo valor
    if (this._resolvedEvents.hasOwnProperty(_type)) {
      subscriber(this._resolvedEvents[_type]);
    }

    this._emitter.subscribe(({ type, value }) => {
      if (type === _type) {
        subscriber(value);
      }
    });
  }

  emit (type, value) {
    // Guardamos el último valor resuelto para el evento
    this._resolvedEvents[type] = value;

    this._emitter.emit({ type, value });
  }
}
