import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalEventsService } from './global-events.service';
import { StorageService } from './storage.service';
import jsSHA from 'jssha';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _configData: any;
    private _promise: Promise<any>;
    private _promiseDone: boolean = false;

    config: any;
    data: any;
    token: any;
    strContent: string = "";

    private _subsidiaryConfig: any;
    constructor(public http: HttpClient,
                private $storage: StorageService,
                private $globalEvents: GlobalEventsService) { }

    async loadConfig(): Promise<any> {
        var urlEnvironment: string = "assets/environment.json";
        this._configData = null;
 
        if (this._promiseDone) {
            return Promise.resolve();
        }
 
        if (this._promise !== null && this._promise !== undefined) {
            return this._promise;
        }
 
        this._promise = this.http
            .get(urlEnvironment, { headers: new HttpHeaders() })
            .toPromise()
            .then((dataEnvironment: any) => { 
                this.http
                .get(dataEnvironment["regional"])
                .toPromise()
                .then((regionalConfig: any)  => {
                        this.http
                        .get(`${regionalConfig.content.toLowerCase()}assets/json/${regionalConfig.config.toLowerCase()}`)
                        .toPromise()
                        .then((data: any) => { 
                                this.$storage.set('subsidiaryConfig', regionalConfig, false);
                                this._subsidiaryConfig = regionalConfig;
                                this._configData = data;
                                this.saveLocal(data);
                                this.$globalEvents.emit('configured', data);
                                this.$globalEvents.emit('urlEnvironment', dataEnvironment);
                            }
                        )
                        .catch((err: any) => { this._promiseDone = true; return Promise.resolve(); });
                    }
                )
                .catch((err: any) => { this._promiseDone = true; return Promise.resolve(); });
                this._promiseDone = true; })
            .catch((err: any) => { this._promiseDone = true; return Promise.resolve(); });
        return this._promise;
    }

    async loadConfigContract(contract: string): Promise<any> {
        //this.delLocal();
        var urlEnvironment: string = "assets/environment.json";
        let dataEnvironment = await this.http.get(urlEnvironment, { headers: new HttpHeaders() }).toPromise();
        let regionalConfig: any = await this.http.get(dataEnvironment["regional"]).toPromise();
        let data = await this.http.get( `${ regionalConfig.content.toLowerCase() }assets/json/${ contract.toLowerCase() }` )
            .toPromise()
            .catch( error => {
                console.log(error);
            });

        if (data)
        {
            this.$storage.set('subsidiaryConfig', regionalConfig, false);
            this._subsidiaryConfig = regionalConfig;
            this._configData = data;
            this.saveLocal(data);
            this.$globalEvents.emit('configured', data);
            this.$globalEvents.emit('urlEnvironment', dataEnvironment);
        }
    }

    get configData(): any {
        return this._configData;
    }

    init() {
        //if (this._subsidiaryConfig)
        //{
            this._subsidiaryConfig = this.$storage.get('subsidiaryConfig', false);
            this.strContent = this._subsidiaryConfig.content;
            return this.http.get(`${this.strContent}assets/json/${this._subsidiaryConfig.config}`);
        //}
    }

    iniciar() {
        // if (this._subsidiaryConfig)
        // {
        //      this.strContent = this._subsidiaryConfig.content;
        //      return this.http.get(`${this.strContent}assets/json/${this._subsidiaryConfig.config}`);
        // }
        // else
        // {
            this._subsidiaryConfig = this.$storage.get('subsidiaryConfig', false);
            this.strContent = this._subsidiaryConfig.content;
            return this.http.get(`${this.strContent}assets/json/${this._subsidiaryConfig.config}`);
        // }
    }

    saveLocal(data) {
        let n = this.$storage.get('version');
        if (!n || n !== data.version) {
            //this.$storage.clear();
            this.$storage
              .set('version', data.version)
              .set('logo', data.logo)
              .set('logoMenu', data.logoMenu)
              .set('iconMenu', data.iconMenu)
              .set('iconMenuPage', data.iconMenuPage)
              .set('departamentos', data.departments)
              .set('menuLateral', data.menuLateral)
              .set('menuTabs', data.menuTabs)
              .set('pages', data.pages)
              .set('terms', data.security.security);
        }
    }

    delLocal() {
        this.$storage.remove('version')
        .remove('version')
        .remove('logo')
        .remove('logoMenu')
        .remove('iconMenu')
        .remove('iconMenuPage')
        .remove('departamentos')
        .remove('menuLateral')
        .remove('menuTabs')
        .remove('pages')
        .remove('terms');
    }

    selectData(namePage) {
        this.config = this.$storage.get('pages');
        if (this.config === null) {
            return false;
        } else {
            for (let page of this.config) {
                if (page.name === namePage) {
                    this.data = page;
                    return this.data;
                }
            }
        }
    }

    selectContractData(contratoAMA, namePage) {
        this.config = this.$storage.get('pages');
        if (this.config === null) {
            return false;
        } else {
            
            let configurar = this.config.filter(contrato => contrato.menu === contratoAMA);

            for (let page of configurar) {
                if (page.name === namePage) {
                    this.data = page;
                    return this.data;
                }
            }
        }
    }

    rootImgs(type) //type (icon, imgs, json)
    {
        let strRoot = `assets/${type.toLowerCase()}/`;
        
        if (this._subsidiaryConfig)
        {
            strRoot = `${this.strContent}assets/${type.toLowerCase()}/`;
        }
        return strRoot;
    }

    logo() {
        let logo: string = "";
        if (this._subsidiaryConfig)
        {
            logo = `${this.strContent}assets/imgs/${this.$storage.get('logo')}`;
        }
        return logo;
    }

    logoMenu() {
        let logo: string = "";
        if (this._subsidiaryConfig)
        {
            logo = `${this.strContent}assets/imgs/${this.$storage.get('logoMenu')}`;
        }
        return logo;
    }

    iconMenu() {
        let logo: string = "";
        if (this._subsidiaryConfig)
        {
            logo = `${this.strContent}assets/icon/${this.$storage.get('iconMenu')}`;
        }
        return logo;
    }

    iconMenuPage() {
        let logo: string = "";
        if (this._subsidiaryConfig)
        {
            logo = `${this.strContent}assets/icon/${this.$storage.get('iconMenuPage')}`;
        }
        return logo;
    }

    getToken() {
        if (this.$storage.get('token') === null) {
            return false;
        } else {
            return true;
        }
    }

    /* CONVENIOS */
    countrys() {
        return this.http.get(`${this.strContent}assets/json/${this.$storage.get('countrys')}`);
    }

    // departments(department: string) {
    //     return this.http.get(`${this.strContent}assets/json/convenios/${department}`);
    // }

    departments() {
        return this.http.get(`${this.strContent}assets/json/${this.$storage.get('departamentos')}`);
    }
        City(departamento: string)
    {
        return this.http.get(`${this.strContent}assets/json/convenios/${departamento}`);
    }

    Service(service: string)
    {
        return this.http.get(`${this.strContent}assets/json/convenios/${service}`);
    }

    getData(data: any) {
        return this.http.get(data);
    }

    getUrl() {
        let url = "assets/environment.json";
        return this.http.get(url);
    }

    ids(url: string) {
        return this.http.get(url);
    }

    captchaCheck(req: any) {
    //console.log("CAPTCHA middleware activated");
    let urlEncodedData = `secret=6LfBaokUAAAAAFKcvaOFrizZR1eLNiaVztitdNRv&response=${req}&remoteip=`;
    return this.http.post('https://www.google.com/recaptcha/api/siteverify', urlEncodedData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
    }

    encryptUserMD5 (usuPass: any) {
        let contrasena = usuPass;
        let bytes = this.str2ab(contrasena);
        let pass = new jsSHA('SHA-512', 'TEXT');
        contrasena = this.ab2str(bytes);
        pass.update(contrasena);
        let hash = pass.getHash('B64');
        return hash;
    }

    private str2ab(str) {
        var buf = new ArrayBuffer(30); // 2 bytes for each char
        var bufView = new Uint8Array(buf);
        for (var i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }

    private ab2str(buf) {
        var fin = '';
        var aux = new Uint8Array(buf),
            l = aux.length,
            i = 0,
            t = 65537,
            e;
        if (l - i < t) { return String.fromCharCode.apply(null, new Uint8Array(buf)); }
        while (l - i >= t || i < l) {
            if (l - i < t) {
                e += l - i;
            } else {
                e = t + i;
            }
            var aux2 = [], h = 0;
            for (var j = i; j < e; j++) {
                aux2[h] = aux[j];
                h++;
            }
            var s = String.fromCharCode.apply(String, new Uint8Array(aux2));
            fin += s;
            if (l - i >= t) {
                i = e;
            } else {
                i = l;
            }
        }
        return fin;
    }
}

export function normalizeString (str: string) {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

