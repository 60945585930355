import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _engine = window.localStorage;

  set(key: string, value: any, namespaced?: boolean) {
    this._engine.setItem(this._getNamespacedKey(key, namespaced), JSON.stringify(value));

    return this;
  }

  get(key: string, namespaced?: boolean) {
    const value = this._engine.getItem(this._getNamespacedKey(key, namespaced));

    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }

  remove(key: string, namespaced?: boolean) {
    this._engine.removeItem(this._getNamespacedKey(key, namespaced));

    return this;
  }

  clear() {
    this._engine.clear();
    return this;
  }

  private _getNamespacedKey(key, namespaced = true) {
    const config = namespaced && this.get('subsidiaryConfig', false);

    // code:project:type__key
    const namespace = config && [config.code.toLowerCase(), config.project, config.strProjectType.toLowerCase()].join(':');

    return (namespace ? `${namespace}__` : '') + key;
  }
}
