import { Component, NgZone } from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from './config.service';
import { GlobalEventsService } from './global-events.service';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { StateNavigationService } from './state-navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  items: Observable<any[]>;
  // @ViewChild(Nav) nav: Nav;

  rootPage: any;
  pages: Array<{ name: string, title: string, type: any, click: string, icon: string }>;
  token: any;
  logoMenu: any;
  menuIcon: any;
  list: any;
  rootImgs: any;
  rootIco: any;
  rootJson: any;
  showBtn: boolean = false;
  deferredPrompt: any;

  firstNavigation = true;

  inIos = false;

  constructor(
      // public app: App,
      public platform: Platform,
      public statusBar: StatusBar,
      public splashScreen: SplashScreen,
      public translate: TranslateService,
      public ConfigProvider: ConfigService,
      private $globalEvents: GlobalEventsService,
      private $storage: StorageService,
      private $ngZone: NgZone,
      private $router: Router,
      private $menu: MenuController,
      private $stateNavigation: StateNavigationService
  ) {
      this.$router
        .events
        .subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.firstNavigation) {
                    this.firstNavigation = false;
                } else {
                    this.$stateNavigation.saveNavigationState(
                        this.$router
                            .getCurrentNavigation()
                            .extras
                            .state ?? {}
                    );
                }
            }
        });

      platform.ready().then(() => {
          statusBar.styleDefault();
          //splashScreen.hide();
          translate.setDefaultLang('es');
      });
      caches.keys().then(function (keys) {
          keys.forEach(function (request, index, array) {
              caches.delete(request);
              console.log(`deleted ${request}`);
          });
      });

      this.handleSplashScreen();

      this.pages = this.$storage.get('menuLateral');

      if (!this.pages) {
        this.$globalEvents.subscribe('configured', data => {
          this.pages = data['menuLateral'];
        });
      } else {
        // Actualizar datos en el localStorage
        this.ConfigProvider.iniciar().subscribe(data => {
          this.ConfigProvider.saveLocal(data);
          this.$globalEvents.emit('configured', data);
          this.pages = data['menuLateral'];
        });
      }

      this.$globalEvents.subscribe('configured', () => {
          this.$ngZone.run(() => {
              this.rootImgs = this.ConfigProvider.rootImgs("imgs");
              this.rootIco = this.ConfigProvider.rootImgs("icon");
              this.rootJson = this.ConfigProvider.rootImgs("json");
              this.logoMenu = this.ConfigProvider.logoMenu();
              this.menuIcon = this.ConfigProvider.iconMenu();
              this.pages = this.$storage.get('menuLateral');
          });
      });

      // Original this.rootPage = LoginPage;
      // Below replacement:
      // this.$router.navigate(['/login']);
      this.rootImgs = this.ConfigProvider.rootImgs("imgs");
      this.rootIco = this.ConfigProvider.rootImgs("icon");
      this.rootJson = this.ConfigProvider.rootImgs("json");
      this.logoMenu = this.ConfigProvider.logoMenu();
      this.menuIcon = this.ConfigProvider.iconMenu();

      window.datalayer.dispositivo = this.platform.platforms().join(" - ");
      /* Original
      app.viewDidEnter.subscribe(
          (view) => {
              this.pageChange(view.instance.name);
          }
      );
      */
      // Below replacement:
      this.$router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.pageChange(event.url.replace(/\//g, ''));
        }
      });

      this.inIos = platform.is('ios');
  }

  get subsidiaryConfigured () {
      const parsed = this.$storage.get('subsidiaryConfig', false);
      return parsed && Object.keys(parsed).length;
  }

  openPage(page) {
      let data = this.ConfigProvider.selectData(page.name);

      // [menuClose] directive replacement
      this.$menu.close();

      if (data.type === undefined)
      {
          data.type = page.type;
      }

      if (data.type === "off") {
          this.$storage
            .remove('token')
            .remove('id')
            .remove('user')
            .remove('idRegDealer')
            .remove('nif');
          // Original: this.nav.setRoot(LoginPage);
          // Below replacement:
          this.$router.navigate(['/login']);
      } else if (data.type === "infor") {
          /* Original: 
          this.nav.setRoot(InforTemplatePage, {
              content: data
          });
          */
          // Below replacement:
          this.$router.navigate(['/infor-template'], {
            state: { content: data }
          });
      } else if (data.type === "call") {
          /* Original:
          this.nav.setRoot(CallTemplatePage, {
              content: data
          });
          */
          // Below replacement:
          this.$router.navigate(['/call-template'], {
            state: { content: data }
          });
      } else if (data.type === "history") {
          // Original: this.nav.setRoot(HistorialPage);
          // Below replacement:
          this.$router.navigate(['/historial']);
      } else if (data.type === "profile") {
          // Original: this.nav.setRoot(PerfilPage);
          // Below replacement:
          this.$router.navigate(['/perfil']);
      } else if (data.type === "contact") {
          // Original:  this.nav.setRoot(ContactoPage);
          // Below replacement:
          this.$router.navigate(['/contacto']);
      } else if (data.type === "mapSeg") {
          // Original: this.nav.setRoot(SeguimientoPage);
          // Below replacement:
          this.$router.navigate(['/seguimiento']);
      } else if (data.type === "menu") {
          /* Original
          this.nav.setRoot(MenuTemplatePage, {
              content: data
          });
          */
          // Below replacement:
          this.$router.navigate(['/menu'], {
            state: { content: data }
          });
      } else if (data.type === "home") {
          /* Original:
          this.nav.setRoot(HomePage, {
              content: data
          });
          */
          // Below replacement:
          this.$router.navigate(['/home'], {
            state: { content: data }
          });
      } else if (data.type === "atencionNacional") {
          /* Original:
          this.nav.setRoot(AtencionPage, {
              content: data
          });
          */
          // Below replacement:
          this.$router.navigate(['/atencion'], {
            state: { content: data }
          });
      } else if (data.type === "conveniosClick") {
          /* Original:
          this.nav.setRoot(ConveniosPage, {
              content: data
          });
          */
          // Below replacement:
          this.$router.navigate(['/convenios'], {
            state: { content: data }
          });
      } else if (data.type === "asisMedica") {
          /* Original:
          this.nav.setRoot(MenuTemplatePage, {
              content: data
          });
          */
          // Below replacement:
          this.$router.navigate(['/menu-template'], {
            state: { content: data }
          });
      } else if (data.type === "asisTelefonica") {
          /* Original:
          this.nav.setRoot(MenuTemplatePage, {
              content: data
          });
          */
          // Below replacement:
          this.$router.navigate(['/menu-template'], {
            state: { content: data }
          });
      }
  }

  pageChange(pageName: string): void {
      if (window.visualizarPaso) {
          window.datalayer.title = pageName;
          window.visualizarPaso({ type: 'pageview' });
      } else {
          setTimeout(() => {
              this.pageChange(pageName);
          }, 200);
      }
  }

  ngOnInit(): void {
  }

  // hide #splash-screen when app is ready
  async handleSplashScreen(): Promise<void> {
      try {
          // wait for App to finish loading
          await this.platform.ready();
      } catch (error) {
          console.error('Platform initialization bug');
      }

      // fade out and remove the #splash-screen
      const splash = document.getElementById('splash-screen');
      // splash.style.opacity = '0';
      setTimeout(() => { splash.remove(); }, 3600);
  }

  menuClose() {
    this.$menu.close();
  }
}
