import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'reset-pass',
    loadChildren: () => import('./reset-pass/reset-pass.module').then( m => m.ResetPassPageModule)
  },
  {
    path: 'create-user',
    loadChildren: () => import('./create-user/create-user.module').then( m => m.CreateUserPageModule)
  },
  {
    path: 'menu-template',
    loadChildren: () => import('./menu-template/menu-template.module').then( m => m.MenuTemplatePageModule)
  },
  {
    path: 'call-template',
    loadChildren: () => import('./call-template/call-template.module').then( m => m.CallTemplatePageModule)
  },
  {
    path: 'convenios',
    loadChildren: () => import('./convenios/convenios.module').then( m => m.ConveniosPageModule)
  },
  {
    path: 'infor-template',
    loadChildren: () => import('./infor-template/infor-template.module').then( m => m.InforTemplatePageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'historial',
    loadChildren: () => import('./historial/historial.module').then( m => m.HistorialPageModule)
  },
  {
    path: 'contacto',
    loadChildren: () => import('./contacto/contacto.module').then( m => m.ContactoPageModule)
  },
  {
    path: 'atencion',
    loadChildren: () => import('./atencion/atencion.module').then( m => m.AtencionPageModule)
  },
  {
    path: 'change-pass',
    loadChildren: () => import('./change-pass/change-pass.module').then( m => m.ChangePassPageModule)
  },
  {
    path: 'convenios-map',
    loadChildren: () => import('./convenios-map/convenios-map.module').then( m => m.ConveniosMapPageModule)
  },
  {
    path: 'doctor-dom',
    loadChildren: () => import('./doctor-dom/doctor-dom.module').then( m => m.DoctorDomPageModule)
  },
  {
    path: 'modal-doc',
    loadChildren: () => import('./modal-doc/modal-doc.module').then( m => m.ModalDocPageModule)
  },
  {
    path: 'seguimiento',
    loadChildren: () => import('./seguimiento/seguimiento.module').then( m => m.SeguimientoPageModule)
  },
  {
    path: 'sucursales',
    loadChildren: () => import('./sucursales/sucursales.module').then( m => m.SucursalesPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
